import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import Modal from 'react-modal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ShowtimeStage.css';
import './modal.css';
function ShowtimeStage() {
  const [events, setEvents] = useState([]);
  const [timeUntilNextStage, setTimeUntilNextStage] = useState('');
  const [nextStage, setNextStage] = useState('');
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [minutesUntil959am, setMinutesUntil959am] = useState(-1);
  const sliderRef = useRef(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get('https://api.whatintheworld.pro/api/events');
        const eventsWithSignups = await Promise.all(
          response.data.map(async (event) => {
            const signupsResponse = await axios.get(
              `https://api.whatintheworld.pro/api/events/signups/${event.eventID}`
            );
            let signups = signupsResponse.data;

            const factions = { 0: 'Horde', 1: 'Alliance' };
            signups = Object.entries(factions).map(([side, name]) => {
              const factionSignup = signups.find((s) => s.side.toString() === side);
              return {
                side: name,
                signupCount: factionSignup ? factionSignup.signupCount : 0,
              };
            });

            const totalSignups = signups.reduce((sum, s) => sum + s.signupCount, 0);

            return { ...event, signups, totalSignups };
          })
        );
        setEvents(eventsWithSignups);
      } catch (error) {
        console.error('Error fetching events', error);
		if (error.response && error.response.data && error.response.data.message) {
			setErrorMessage(error.response.data.message);
		  } else {
			setErrorMessage('An error occurred while voting for the proposal.');
		  }
		setErrorModalIsOpen(true);
      }
    };

    const fetchTimeRemaining = async () => {
      try {
        const response = await axios.get('https://api.whatintheworld.pro/api/stageremaining');
        setTimeUntilNextStage(response.data.minutesUntilNextStage);
        setNextStage(response.data.nextStage);
        setMinutesUntil959am(response.data.minutesUntil959am);
      } catch (error) {
        console.error('Error fetching time until next stage', error);
		if (error.response && error.response.data && error.response.data.message) {
			setErrorMessage(error.response.data.message);
		  } else {
			setErrorMessage('An error occurred while voting for the proposal.');
		  }
		setErrorModalIsOpen(true);
      }
    };

    fetchEvents();
    fetchTimeRemaining();
  }, []);

  const settings = {
    infinite: true,
    centerMode: true,
    centerPadding: '0',
    slidesToShow: events.length === 1 ? 1 : events.length === 2 ? 2 : 3,
    focusOnSelect: true,
    arrows: true,
    afterChange: (index) => {
      // Display information about the selected event
      // Adjust the index to handle wrapping when sliding past the last or first element
      const eventIndex = (index + events.length) % events.length;
      setChosenEvent(events[eventIndex]);
    },
  };

  const [chosenEvent, setChosenEvent] = useState(null); // Initialize to null
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };
  // Use useEffect to set chosenEvent once events data is fetched
  useEffect(() => {
    if (events.length > 0) {
      setChosenEvent(events[0]); // Initially set to the first event
    }
  }, [events]);

  return (
  <div class="showtime-container">
		<div class="event-slider">
		  <Slider {...settings}>
			{events.map((event, index) => (
			  <div
				key={index}
				className={`event-slide ${event === chosenEvent ? 'chosen-element' : ''}`}
			  >
				<div class="floating-rectangle">
				  <p>{event.name}</p>
				  
				</div>
			  </div>
			))}
		  </Slider>
		</div>
		<div class="shsdetails">
			<div class="shsevent-details">
			  <div class="shsevent-details-text">
			  {chosenEvent && (
				<>
				  <h2>{chosenEvent.name}</h2>
				  <p> by {chosenEvent.author}</p>
				  <p>{chosenEvent.description}</p>
				  <p>Meetup Place: {chosenEvent.location}</p>
				  <p>Start Time: {chosenEvent.time}</p>
				  {chosenEvent.signups.map((signup, idx) => (
					<a key={idx}>
					  {signup.side} Signups: {signup.signupCount} |
					</a>
				  ))}
				  <p>Total Signups: {chosenEvent.totalSignups}</p>
				  
				  
				  
				</>
			  )}
			  </div>
			</div>
		  
			<div class="shstime-details">
			  <div class="shstime-details-text">
			  <h2>Now: Showtime Stage</h2>
			  <p>{timeUntilNextStage} minutes left</p>
			  <h3>Until Proposing Stage</h3>
			  <p> @10pm EST</p>
			  {minutesUntil959am > 0 && (
				<p>Voting stage manually starts in {minutesUntil959am} minutes</p>
			  )}
			  <p> Looking for Login or Registration?</p>
			  <p>It opens at Proposing Stage; jump into the game, don't miss the Showtime!</p>
			  </div>
			</div>
		</div>
		<Modal
				  isOpen={errorModalIsOpen}
				  onRequestClose={closeErrorModal}
				  contentLabel="Error Modal"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Error</h3>
					<p>{errorMessage}</p>
					<button onClick={closeErrorModal}>Close</button>
				  </div>
				</Modal>

   </div>
);
}

export default ShowtimeStage;