import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ProposingStage.css';
import Slider from 'react-slick';
import Modal from 'react-modal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './modal.css';
function ProposingStage({userID, password, author}) {
  const [proposals, setProposals] = useState([]);
  const [timeUntilNextStage, setTimeUntilNextStage] = useState('');
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [nextStage, setNextStage] = useState(''); // New state variable for next stage name
  const [minutesUntil959am, setMinutesUntil959am] = useState(-1); // New state for minutes until 9:59 am
  const sliderRef = useRef(null);
  
  const [newProposal, setNewProposal] = useState({
    name: '',
    description: '',
    location: '',
    time: '',
	userID: userID,
	passwordSus: password,
	author: author,
  });

  useEffect(() => {
    // Fetch and set the current proposals from the backend
    const fetchProposals = async () => {
      try {
        const response = await axios.get('https://api.whatintheworld.pro/api/proposals');
        setProposals(response.data);
		console.log(`1 at proposing the password is: ${password}`);
	    console.log(`1 proposal packet is: ${newProposal}`);
		// make form be filled by default with this
		setNewProposal({
		    name: '',
			description: '',
			location: '',
			time: '',
			userID: userID,
			passwordSus: password,
			author: author,
		});
      } catch (error) {
        console.error('Error fetching proposals', error);
		if (error.response && error.response.data && error.response.data.message) {
			setErrorMessage(error.response.data.message);
		  } else {
			setErrorMessage('An error occurred while voting for the proposal.');
		  }
		setErrorModalIsOpen(true);
      }
    };
	const fetchTimeRemaining = async () => {
      try {
        const response = await axios.get('https://api.whatintheworld.pro/api/stageremaining');
        setTimeUntilNextStage(response.data.minutesUntilNextStage);
		setNextStage(response.data.nextStage); // Set the next stage name
		setMinutesUntil959am(response.data.minutesUntil959am); // Set the minutes until 9:59 am
      } catch (error) {
        console.error('Error fetching time until next stage', error);
		if (error.response && error.response.data && error.response.data.message) {
			setErrorMessage(error.response.data.message);
		  } else {
			setErrorMessage('An error occurred while voting for the proposal.');
		  }
		setErrorModalIsOpen(true);
      }
    };
    fetchProposals();
	fetchTimeRemaining();
  }, []);

  const handleSubmitProposal = async () => {
	  console.log(`at proposing the password is: ${password}`);
	  console.log(`at proposing the username is: ${author}`);
	  console.log(`proposal packet is: ${newProposal}`);
	  
	  if (
      newProposal.name.trim() === '' ||
      newProposal.description.trim() === '' ||
      newProposal.location.trim() === '' ||
      newProposal.time.trim() === ''
    ) {
      // Display the error modal
      setErrorMessage('Make sure to fill all the input fields!');
      setErrorModalIsOpen(true);
    } else {
		
		
		try {
			// Send the new proposal to the backend
			const propResponse = await axios.post('https://api.whatintheworld.pro/api/proposals', newProposal);
			
			// Clear the form fields after successful submission
			setNewProposal({
			  name: '',
			  description: '',
			  location: '',
			  time: '',
			  userID: userID,
			  passwordSus: password,
			  author: author,
			});

			// Fetch the updated list of proposals and update the state
			const response = await axios.get('https://api.whatintheworld.pro/api/proposals');
			setProposals(response.data);

			// Refetch the time remaining to update the 'proposals left'
			const timeResponse = await axios.get('https://api.whatintheworld.pro/api/stageremaining');
			setTimeUntilNextStage(timeResponse.data.minutesUntilNextStage);
			setSuccessMessage("Proposal submitted, gj!");
			setSuccessModalIsOpen(true);
		  } catch (error) {
			console.error('Error submitting proposal', error);
				if (error.response && error.response.data && error.response.data.message) {
				setErrorMessage(error.response.data.message);
			  } else {
				setErrorMessage('An error occurred while voting for the proposal.');
			  }
			setErrorModalIsOpen(true);
		  }
		
	}
	  
	  
	  
	  
	  
	  
	};
const settings = {
    infinite: true,
    centerMode: true,
    centerPadding: '0',
    slidesToShow: proposals.length === 1 ? 1 : proposals.length === 2 ? 2 : 3,
    focusOnSelect: true,
    arrows: true,
    afterChange: (index) => {
      // Display information about the selected event
      // Adjust the index to handle wrapping when sliding past the last or first element
      const proposalIndex = (index + proposals.length) % proposals.length;
      setchosenProposal(proposals[proposalIndex]);
    },
  };
const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };
  const [chosenProposal, setchosenProposal] = useState(null); // Initialize to null

  // Use useEffect to set chosenProposal once events data is fetched
  useEffect(() => {
    if (proposals.length > 0) {
      setchosenProposal(proposals[0]); // Initially set to the first event
    }
  }, [proposals]);
  
  
  return (
    <div className="showtime-container">
      
	  
	  
	  
	  
	  
	  
			<div class="event-slider">
			  <Slider {...settings}>
				{proposals.map((proposal, index) => (
				  <div
					key={index}
					className={`event-slide ${proposal === chosenProposal ? 'chosen-element' : ''}`}
				  >
					<div class="floating-rectangle">
					  <p>{proposal.name}</p>
					  
					</div>
				  </div>
				))}
			  </Slider>
			</div>
		  
		  
		  
			<div class="psdetails">
			
				
			
			
				<div class="psevent-details">
				  <div class="psevent-details-text">


				      {!chosenProposal && (
					  
					  <h2> Nobody Submitted Proposals, yet...</h2>
					  )}
					  {chosenProposal && (
						<>
						  <h2>{chosenProposal.name}</h2>
						  <p> by {chosenProposal.author}</p>
						  <p>{chosenProposal.description}</p>
						  <p>Meetup Place: {chosenProposal.location}</p>
						  <p>Start Time: {chosenProposal.time}</p>
						</>
					  )}

				  </div>
				</div>
				<div class="proposal-details">
					<div class="proposal-details-interactables">
						<input
							className="proposalForm-name"
							type="text"
							placeholder="Your Event's Name"
							value={newProposal.name}
							onChange={(e) => setNewProposal({ ...newProposal, name: e.target.value })}
							maxLength="25" 
						/>
						<textarea
							class="proposalForm-descriptionField"
							type="text"
							placeholder="Your Event's Description (Location and Time are set separately in fields below)"
							value={newProposal.description}
							onChange={(e) => setNewProposal({ ...newProposal, description: e.target.value })}
							maxLength="198" 
						/>
						<input
							className="proposalForm-locField"
							type="text"
							placeholder="Your Event's Meetup Location"
							value={newProposal.location}
							onChange={(e) => setNewProposal({ ...newProposal, location: e.target.value })}
							maxLength="25" 
						/>
						<input
							className="proposalForm-locField"
							type="datetime-local"
							placeholder="Time"
							value={newProposal.time}
							onChange={(e) => setNewProposal({ ...newProposal, time: e.target.value })}
						/>
						<a className="proposalForm-submitButton" onClick={handleSubmitProposal}></a>
					</div>
				</div>
	  
				<div class="pstime-details">
					<div class="pstime-details-text">
							  <h2>Now: Proposing Stage</h2>
							  {timeUntilNextStage < 0 
								  ? `${Math.abs(timeUntilNextStage)} proposals left`
								  : `${timeUntilNextStage} minutes`}
							  <h3>Until Voting Stage</h3>
							  

							  {minutesUntil959am >= 0 && (
								<p>If nobody proposes, system will automatically suggest up to 5 proposals and will move to Voting Stage in {minutesUntil959am} minutes</p>
							  )}
					</div>
					

				</div>
	  
			</div>
	  
	  
	  
	  
	  
	  
	  
	  
	  
	
	  
		<Modal
				  isOpen={errorModalIsOpen}
				  onRequestClose={closeErrorModal}
				  contentLabel="Error Modal"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Error</h3>
					<p>{errorMessage}</p>
					<button onClick={closeErrorModal}>Close</button>
				  </div>
				</Modal>

				<Modal
				  isOpen={successModalIsOpen}
				  onRequestClose={closeSuccessModal}
				  contentLabel="Success Modal"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Success</h3>
					<p>{successMessage}</p>
					<button onClick={closeSuccessModal}>Close</button>
				  </div>
				</Modal>  
	</div>
	
	
	
  );
}

export default ProposingStage;