import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Authentication from './Authentication';
import ProposingStage from './ProposingStage';
import VotingStage from './VotingStage';
import SignupStage from './SignupStage';
import ShowtimeStage from './ShowtimeStage';

function App() {
  const [currentStage, setCurrentStage] = useState('');
  const [isInitialSetupComplete, setIsInitialSetupComplete] = useState(false);
  const [author, setUsernameApp] = useState('');
  const [userID, setUserID] = useState('');
  const [userPassword, setPasswordApp] = useState('');
  

  useEffect(() => {
    // Fetch the current stage from the backend
    const fetchCurrentStage = async () => {
      try {
        const response = await axios.get('https://api.whatintheworld.pro/api/stage');
        setCurrentStage(response.data.stage);
      } catch (error) {
        console.error('Error fetching current stage', error);
      }
    };

    fetchCurrentStage();
  }, []);

  return (
    <div className="App">
      {!isInitialSetupComplete ? (
        <>
		
		
		
		
		{currentStage === 'showtime' ? (
			<ShowtimeStage/>
		) : (
			<Authentication
			  onInitialSetupComplete={() => setIsInitialSetupComplete(true)}
			  setUserID={setUserID} // Pass setUserID function
              setPasswordApp={setPasswordApp} // Pass setPassword function
			  setUsernameApp={setUsernameApp}
			/>
		)}
		
		
		</>
      ) : (
        <>
          {currentStage === 'proposing' && <ProposingStage userID={userID} password={userPassword} author={author}/>}
          {currentStage === 'voting' && <VotingStage userID={userID} password={userPassword} />}
          {currentStage === 'signup' && <SignupStage userID={userID} password={userPassword} />}
		  {currentStage === 'showtime' && <ShowtimeStage/>}
        </>
      )}
    </div>
  );
}

export default App;
