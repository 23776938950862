import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SignupStage.css';
import './modal.css';
function SignupStage({ userID, password }) {
  const [events, setEvents] = useState([]);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [timeUntilNextStage, setTimeUntilNextStage] = useState('');
  const [nextStage, setNextStage] = useState(''); // New state variable for next stage name
  const [minutesUntil959am, setMinutesUntil959am] = useState(-1); // New state for minutes until 9:59 am
  const sliderRef = useRef(null);

  useEffect(() => {
    const fetchEvents = async () => {
        try {
            const response = await axios.get('https://api.whatintheworld.pro/api/events');
            const eventsWithSignups = await Promise.all(response.data.map(async (event) => {
                const signupsResponse = await axios.get(`https://api.whatintheworld.pro/api/events/signups/${event.eventID}`);
                let signups = signupsResponse.data;

                // Ensure both factions are represented
                const factions = { 0: 'Horde', 1: 'Alliance' };
                signups = Object.entries(factions).map(([side, name]) => {
                    const factionSignup = signups.find(s => s.side.toString() === side);
                    return { side: name, signupCount: factionSignup ? factionSignup.signupCount : 0 };
                });

                // Calculate total signups
                const totalSignups = signups.reduce((sum, s) => sum + s.signupCount, 0);

                return { ...event, signups, totalSignups };
            }));
			
            setEvents(eventsWithSignups);
        } catch (error) {
            console.error('Error fetching events', error);
			if (error.response && error.response.data && error.response.data.message) {
			setErrorMessage(error.response.data.message);
		  } else {
			setErrorMessage('An error occurred while voting for the proposal.');
		  }
		setErrorModalIsOpen(true);
        }
    };
	const fetchTimeRemaining = async () => {
		  try {
			const response = await axios.get('https://api.whatintheworld.pro/api/stageremaining');
			setTimeUntilNextStage(response.data.minutesUntilNextStage);
			setNextStage(response.data.nextStage); // Set the next stage name
			setMinutesUntil959am(response.data.minutesUntil959am); // Set the minutes until 9:59 am
		  } catch (error) {
			console.error('Error fetching time until next stage', error);
			if (error.response && error.response.data && error.response.data.message) {
			setErrorMessage(error.response.data.message);
		  } else {
			setErrorMessage('An error occurred while voting for the proposal.');
		  }
		setErrorModalIsOpen(true);
		  }
	};
    fetchEvents();
	fetchTimeRemaining();
	
}, []);

  const handleEventSignup = async (eventID) => {
  try {
    // Signing up the user for the event
    const signupResponse = await axios.post('https://api.whatintheworld.pro/api/events/signup', {
      userID: userID,
      eventID: eventID,
	  passwordSus: password,
    });
    setSuccessMessage(signupResponse.data.message);
    setSuccessModalIsOpen(true);

    // Fetching user's faction
    const factionResponse = await axios.get(`https://api.whatintheworld.pro/api/userfaction/${userID}`);
    const userFaction = factionResponse.data[0].side; // 0 for Horde, 1 for Alliance

    // Update the event count based on the user's faction
    const updatedEvents = events.map(event => {
      if (event.eventID === eventID) {
        // Updating the faction count
        const updatedSignups = event.signups.map(signup => {
          if ((signup.side === 'Horde' && userFaction === 0) || (signup.side === 'Alliance' && userFaction === 1)) {
            return { ...signup, signupCount: signup.signupCount + 1 };
          }
          return signup;
        });

        // Updating the total signups
        const totalSignups = updatedSignups.reduce((sum, s) => sum + s.signupCount, 0);

        return { ...event, signups: updatedSignups, totalSignups };
      }
      return event;
    });

    setEvents(updatedEvents);
  } catch (error) {
    console.error('Error signing up for event', error);
    if (error.response && error.response.data && error.response.data.message) {
      setErrorMessage(error.response.data.message);
    } else {
      setErrorMessage('An error occurred while signing up for the event.');
    }
    setErrorModalIsOpen(true);
  }
};
  const settings = {
    infinite: true,
    centerMode: true,
    centerPadding: '0',
    slidesToShow: events.length === 1 ? 1 : events.length === 2 ? 2 : 3,
    focusOnSelect: true,
    arrows: true,
    afterChange: (index) => {
      // Display information about the selected event
      // Adjust the index to handle wrapping when sliding past the last or first element
      const eventIndex = (index + events.length) % events.length;
      setChosenEvent(events[eventIndex]);
    },
  };

  const [chosenEvent, setChosenEvent] = useState(null); // Initialize to null

  // Use useEffect to set chosenEvent once events data is fetched
  useEffect(() => {
    if (events.length > 0) {
      setChosenEvent(events[0]); // Initially set to the first event
    }
  }, [events]);
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };

  return (
    <div className="showtime-container">

				  
				  
			<div class="event-slider">
			  <Slider {...settings}>
				{events.map((event, index) => (
				  <div
					key={index}
					className={`event-slide ${event === chosenEvent ? 'chosen-element' : ''}`}
				  >
					<div class="floating-rectangle">
					  <p>{event.name}</p>
					  
					</div>
				  </div>
				))}
			  </Slider>
			</div>	  
				  
				  
			<div class="ssdetails">

				<div class="ssevent-details">
				  <div class="ssevent-details-text">

					  {chosenEvent && (
						<>
						  <h2>{chosenEvent.name}</h2>
						  <p> by {chosenEvent.author}</p>
						  <p>{chosenEvent.description}</p>
						  <p>Location: {chosenEvent.location}</p>
						  <p>Start Time: {chosenEvent.time}</p>
						  {chosenEvent.signups.map((signup, idx) => (
							<a key={idx}>
							  {signup.side} Signups: {signup.signupCount} |
							</a>
						  ))}
						  <p>Total Signups: {chosenEvent.totalSignups}</p>
						  <button onClick={() => handleEventSignup(chosenEvent.eventID)}>Sign Up</button>
						</>
					  )}

				  </div>
				</div>
				  
				  
				<div class="sstime-details">
				  <div class="sstime-details-text">
					  <h2>Now: Signup Stage</h2>
					  <p>{timeUntilNextStage} minutes left</p>
					  <h3>Until Showtime Stage</h3>
					  <p> @6pm EST</p>
					  {minutesUntil959am > 0 && (
						<p>Voting stage manually starts in {minutesUntil959am} minutes</p>
					  )}
				  </div>
				</div>
			</div>
				  
				  
				  
	
				  
				  
				<Modal
				  isOpen={errorModalIsOpen}
				  onRequestClose={closeErrorModal}
				  contentLabel="Error Modal"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Error</h3>
					<p>{errorMessage}</p>
					<button onClick={closeErrorModal}>Close</button>
				  </div>
				</Modal>

				<Modal
				  isOpen={successModalIsOpen}
				  onRequestClose={closeSuccessModal}
				  contentLabel="Success Modal"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Success</h3>
					<p>{successMessage}</p>
					<button onClick={closeSuccessModal}>Close</button>
				  </div>
				</Modal>
				
				

	</div>
  );
}

export default SignupStage;