import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import './factionPage.css'; // Import your CSS file
import './modal.css';
function Authentication({ onInitialSetupComplete, setUserID, setPasswordApp, setUsernameApp }) {
  const [faction, setFaction] = useState('');
  const [inputUsername, setInputUsername] = useState('');
  const [username, setUsername] = useState('');
  const [discordName, setDiscordName] = useState('');
  const [password, setPassword] = useState(''); 
  const [isExistingUser, setIsExistingUser] = useState(false);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [whatInTheWorldModalIsOpen, setWhatInTheWorldModalIsOpen] = useState(false);
  const [questionNameModalIsOpen, setQuestionNameModalIsOpen] = useState(false);
  const [questionDiscModalIsOpen, setQuestionDiscModalIsOpen] = useState(false);
  const [questionPassModalIsOpen, setQuestionPassModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  


  const handleFactionSelection = (selectedFaction) => {
    setFaction(selectedFaction);
  };

  const handleUsernameVerification = async (usernameToVerify) => {
    try {
      const response = await axios.get(`https://api.whatintheworld.pro/api/users`);
      const users = response.data;

      // Check if the username exists in the list of users (downcased)
      const usernameExists = users.some((user) => user.ingameName.toLowerCase() === usernameToVerify.toLowerCase());

      if (usernameExists) {
        setIsExistingUser(true);
        console.log('Username exists:', usernameToVerify);
      } else {
        setIsExistingUser(false);
        console.log('Username does not exist:', usernameToVerify);
      }
    } catch (error) {
      console.error('Error in verifying username', error);
	  if (error.response && error.response.data && error.response.data.message) {
			setErrorMessage(error.response.data.message);
		  } else {
			setErrorMessage(`Can't reach character name verification servers... Either they're on fire, or your internet has started to... slow down a bit...`);
		  }
		setErrorModalIsOpen(true);
    }
  };

  const handleAuthentication = async () => {
  // Handle user authentication or registration with backend API calls
  // Redirect to the main application component on successful authentication or registration
  if (isExistingUser) {
    // Handle existing user login
    try {
      const side = faction === 'Horde' ? 0 : 1;
      //console.log('password:', password);
	  setPasswordApp(password);
      const response = await axios.post('https://api.whatintheworld.pro/api/login', {
        username: username.toLowerCase(), // Convert to lowercase before sending
        side,
        password,
      });
		
      // If login is successful, set the user ID in state
	  
      setUserID(response.data.userID); // Set userID in the parent component's state
	  
	  setPasswordApp(password);
	  setUsernameApp(username.toLowerCase());
      console.log('login successful');
      // Signal that the initial setup is complete
      onInitialSetupComplete();
    } catch (error) {
      console.error('Error in user login', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Wrong.. Check password or faction!'); // Default message
      }
      setErrorModalIsOpen(true);
    }
  } else {
    try {
      // Handle new user registration
      const side = faction === 'Horde' ? 0 : 1;
      const response = await axios.post('https://api.whatintheworld.pro/api/register', {
        ingameName: username.toLowerCase(), // Convert to lowercase before sending
        side,
        password: discordName, // assuming password is the discord name for new users
      });
      // If registration is successful, set the user ID in state
      setUserID(response.data.userID); // Set userID in the parent component's state
	  setPasswordApp(discordName);
	  setUsernameApp(username.toLowerCase());
      console.log('registration successful');
      // Signal that the initial setup is complete
      onInitialSetupComplete();
    } catch (error) {
      console.error('Error in user registration', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred during registration.'); // Default message
      }
      setErrorModalIsOpen(true);
    }
  }
};

  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
	window.location.reload();
  };
const closeWhatInTheWorldModal = () => {
	setWhatInTheWorldModalIsOpen(false);
}
const closeQuestionNameModal = () => {
	setQuestionNameModalIsOpen(false);
}
const closeQuestionDiscModal = () => {
	setQuestionDiscModalIsOpen(false);
}
const closeQuestionPassModal = () => {
	setQuestionPassModalIsOpen(false);
}



  return (
    <div>
	  
      {!faction && (
	  <div className="faction-page">
	      <button className="whatInTheWorld" onClick={() => setWhatInTheWorldModalIsOpen(true)}>
		  </button>
        <div className="faction-selection">
          <button className="horde-button" onClick={() => handleFactionSelection('Horde')}>
            
          </button>
          <button className="alliance-button" onClick={() => handleFactionSelection('Alliance')}>
            
          </button>
        </div>
	  </div>
      )}
      
      {faction === 'Horde' && !username && (
        <div className="horde-username-page">
          
		  <div class="verifyStack">
			  <input
				className="horde-username-input"
				type="text"
				placeholder="Your Character Name"
				onChange={(e) => setInputUsername(e.target.value)}
			  />
			  <button
					className="hordeQuestion"
					onClick={ () => {
						setQuestionNameModalIsOpen(true);
					}}
				  >
				</button>
		  </div>
			  <button
				className="horde-verify-button"
				onClick={() => {
				  setUsername(inputUsername.toLowerCase());
				  handleUsernameVerification(inputUsername.toLowerCase());
				}}
			  >
				Verify
			  </button>
			  
		  
        </div>
      )}

      {faction === 'Alliance' && !username && (
        <div className="alliance-username-page">
          <div class="verifyStack">
			  <input
				className="alliance-username-input"
				type="text"
				placeholder="Your Character Name"
				onChange={(e) => setInputUsername(e.target.value)}
			  />
			  <button
					className="allianceQuestion"
					onClick={ () => {
						setQuestionNameModalIsOpen(true);
					}}
				  >
				  </button>
		  </div>
			  <button
				className="alliance-verify-button"
				onClick={() => {
				  setUsername(inputUsername.toLowerCase());
				  handleUsernameVerification(inputUsername.toLowerCase());
				}}
			  >
				Verify
			  </button>
			  
        </div>
      )}
	  
	  
	  
	  
	  
      {username && isExistingUser && (
		  faction === 'Alliance' ? (
			<div className="alliance-username-page">
			  <div class="verifyStack">
					<input className="alliance-username-input" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
					<button
						className="allianceQuestion"
						onClick={ () => {
							setQuestionPassModalIsOpen(true);
						}}
					  >
					  </button>
			    </div>
				
					  <button className="alliance-verify-button" onClick={handleAuthentication}>Login</button>
					  
			  
			  
			  
			  
			  
			  

			  
			  
			</div>
		  ) : faction === 'Horde' ? (
			<div className="horde-username-page">
			  <div class="verifyStack">
				<input className="horde-username-input" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
					<button
						className="allianceQuestion"
						onClick={ () => {
							setQuestionPassModalIsOpen(true);
						}}
					  >
					  </button>
			    </div>
				
					  <button className="horde-verify-button" onClick={handleAuthentication}>Login</button>
					  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			</div>
		  ) : null
		)}

		{username && !isExistingUser && (
		  faction === 'Alliance' ? (
			<div className="alliance-username-page">
				  <div class="verifyStack">
					<input className="alliance-username-input" type="text" placeholder="Discord Name" onChange={(e) => setDiscordName(e.target.value)} />
					<button
										className="allianceQuestion"
										onClick={ () => {
											setQuestionDiscModalIsOpen(true);
										}}
									  >
					</button>
				</div>
				
						  <button className="alliance-verify-button" onClick={handleAuthentication}>Register</button>
						  
			  
			  
			  
			  
			  
			  
			  
			  
			</div>
		  ) : faction === 'Horde' ? (
			<div className="horde-username-page">
			  <div class="verifyStack">
					<input className="horde-username-input" type="text" placeholder="Discord Name" onChange={(e) => setDiscordName(e.target.value)} />
					<button
												className="allianceQuestion"
												onClick={ () => {
													setQuestionDiscModalIsOpen(true);
												}}
											  >
					</button>
				</div>
				
						<button className="horde-verify-button" onClick={handleAuthentication}>Register</button>
						
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			  
			</div>
		  ) : null
		)}
	  
	  
	  
	  
	  
	  
	        
	            <Modal
				  isOpen={errorModalIsOpen}
				  onRequestClose={closeErrorModal}
				  contentLabel="Error Modal"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Error</h3>
					<p>{errorMessage}</p>
					<button onClick={closeErrorModal}>Close</button>
				  </div>
				</Modal>
				
				<Modal
				  isOpen={whatInTheWorldModalIsOpen}
				  onRequestClose={closeWhatInTheWorldModal}
				  contentLabel="what in the World App Description!"
				  className="whatInTheWorldModal whatInTheWorldModal-active"
				>
				  <div className="whatInTheWorldModal-content">
					<h3>what in the World... App</h3>
					<p>Proposing Stage - Will help you craft and submit up to 2 event-proposals per character (starting 10pm-EST and ending when 25 proposals are submitted or 10am-EST on next day).</p>
					<p>Voting Stage - Allows to vote for any 1 of up to 25 proposals (starts when 25 proposals are submitted or 9:59 am EST is reached); only top-5 proposals will be selected as Events at 10am EST. </p>
					<p>Signup Stage - Will help you sign-up for any 1 of 5 events (ex-proposals), and reserve your spot for the Victory!</p>
					
				  </div>
				  <button class="whatInTheWorldModalCloseButton" onClick={closeWhatInTheWorldModal}>Close</button>
				</Modal>
				
				<Modal
				  isOpen={questionNameModalIsOpen}
				  onRequestClose={closeQuestionNameModal}
				  contentLabel="Tooltip"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Your Character Name</h3>
					<p>... needs to be from Crusader Strike US server</p>
					<button onClick={closeQuestionNameModal}>Close</button>
				  </div>
				</Modal>
				<Modal
				  isOpen={questionDiscModalIsOpen}
				  onRequestClose={closeQuestionDiscModal}
				  contentLabel="Tooltip"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Please Remember</h3>
					<p>... your pair of character name and discord name, as you may use it for future login to your account.</p>
					<button onClick={closeQuestionDiscModal}>Close</button>
				  </div>
				</Modal>
				<Modal
				  isOpen={questionPassModalIsOpen}
				  onRequestClose={closeQuestionPassModal}
				  contentLabel="Tooltip"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Password</h3>
					<p>... is your discord name that you've entered when registering.</p>
					<button onClick={closeQuestionPassModal}>Close</button>
				  </div>
				</Modal>
				
				
				
    </div>
  );
}

export default Authentication;