import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './VotingStage.css';
import './modal.css';
function VotingStage({ userID, password }) {
  const [proposals, setProposals] = useState([]);
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [successModalIsOpen, setSuccessModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [timeUntilNextStage, setTimeUntilNextStage] = useState('');
  const [nextStage, setNextStage] = useState(''); // New state variable for next stage name
  const [minutesUntil959am, setMinutesUntil959am] = useState(-1); // New state for minutes until 9:59 am
  const sliderRef = useRef(null);
  useEffect(() => {
    const fetchProposals = async () => {
      try {
        const response = await axios.get('https://api.whatintheworld.pro/api/proposals');
        setProposals(response.data);

        // Fetch and update vote counts for each proposal
        const voteCounts = await Promise.all(
          response.data.map(async (proposal) => {
            const voteCountResponse = await axios.get(`https://api.whatintheworld.pro/api/votes/tally/${proposal.propID}`);
            return { propID: proposal.propID, voteCount: voteCountResponse.data.voteCount };
          })
        );

        // Merge vote counts into the proposals data
        const updatedProposals = response.data.map((proposal) => {
          const voteCountData = voteCounts.find((item) => item.propID === proposal.propID);
          return {
            ...proposal,
            voteCount: voteCountData ? voteCountData.voteCount : 0,
          };
        });
		
		
		
        setProposals(updatedProposals);
		
      } catch (error) {
        console.error('Error fetching proposals', error);
		if (error.response && error.response.data && error.response.data.message) {
			setErrorMessage(error.response.data.message);
		  } else {
			setErrorMessage('An error occurred while voting for the proposal.');
		  }
		setErrorModalIsOpen(true);
      }
    };
	const fetchTimeRemaining = async () => {
		  try {
			const response = await axios.get('https://api.whatintheworld.pro/api/stageremaining');
			setTimeUntilNextStage(response.data.minutesUntilNextStage);
			setNextStage(response.data.nextStage); // Set the next stage name
			setMinutesUntil959am(response.data.minutesUntil959am); // Set the minutes until 9:59 am
		  } catch (error) {
			console.error('Error fetching time until next stage', error);
		  }
		};
    fetchProposals();
	fetchTimeRemaining();
  }, []);

  const handleVote = async (proposalId) => {
    try {
      const response = await axios.post('https://api.whatintheworld.pro/api/votes', { userID, proposalID: proposalId, passwordSus: password});
      setSuccessMessage(response.data.message);
      setSuccessModalIsOpen(true);
      // Update proposals' vote counts
      const updatedProposals = proposals.map((proposal) => {
        if (proposal.propID === proposalId) {
          return { ...proposal, voteCount: proposal.voteCount + 1 };
        }
        return proposal;
      });
      setProposals(updatedProposals);
    } catch (error) {
      console.error('Error voting for proposal', error);
      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An error occurred while voting for the proposal.');
      }
      setErrorModalIsOpen(true);
    }
  };
const settings = {
    infinite: true,
    centerMode: true,
    centerPadding: '0',
    slidesToShow: proposals.length === 1 ? 1 : proposals.length === 2 ? 2 : 3,
    focusOnSelect: true,
    arrows: true,
    afterChange: (index) => {
      // Display information about the selected event
      // Adjust the index to handle wrapping when sliding past the last or first element
      const proposalIndex = (index + proposals.length) % proposals.length;
      setchosenProposal(proposals[proposalIndex]);
    },
  };

  const [chosenProposal, setchosenProposal] = useState(null); // Initialize to null

  // Use useEffect to set chosenProposal once events data is fetched
  useEffect(() => {
    if (proposals.length > 0) {
      setchosenProposal(proposals[0]); // Initially set to the first event
    }
  }, [proposals]);
  
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const closeSuccessModal = () => {
    setSuccessModalIsOpen(false);
  };

  return (
    <div className="showtime-container">
      
		  
		  
		  
		  
		  <div class="event-slider">
			  <Slider {...settings}>
				{proposals.map((proposal, index) => (
				  <div
					key={index}
					className={`event-slide ${proposal === chosenProposal ? 'chosen-element' : ''}`}
				  >
					<div class="floating-rectangle">
					  <p>{proposal.name}</p>
					  
					</div>
				  </div>
				))}
			  </Slider>
			</div>
		  
		  
		  
			<div class="vsdetails">
				<div class="vsevent-details">
				  <div class="vsevent-details-text">

					  {chosenProposal && (
						<>
						  <h2>{chosenProposal.name}</h2>
						  <p> by {chosenProposal.author}</p>
						  <p>{chosenProposal.description}</p>
						  <p>Location: {chosenProposal.location}</p>
						  <p>Start Time: {chosenProposal.time}</p>
						  <p>Vote Count: {chosenProposal.voteCount}</p>
						  <button onClick={() => handleVote(chosenProposal.propID)}>Vote</button>
						</>
					  )}

				  </div>
				</div>
	  
	  
				<div class="vstime-details">
					<div class="vstime-details-text">
							  <h2>Now: Voting Stage</h2>
							  <p>{timeUntilNextStage} minutes left</p>
							  <h3>Until Signup Stage</h3>
							  <p> @10am EST</p>
							  {minutesUntil959am > 0 && (
								<p>Voting stage manually starts in {minutesUntil959am} minutes</p>
							  )}
					</div>
				</div>
	  
			</div>
	  
	  
				<Modal
				  isOpen={errorModalIsOpen}
				  onRequestClose={closeErrorModal}
				  contentLabel="Error Modal"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Error</h3>
					<p>{errorMessage}</p>
					<button onClick={closeErrorModal}>Close</button>
				  </div>
				</Modal>

				<Modal
				  isOpen={successModalIsOpen}
				  onRequestClose={closeSuccessModal}
				  contentLabel="Success Modal"
				  className="modal modal-active"
				>
				  <div className="modal-content">
					<h3>Success</h3>
					<p>{successMessage}</p>
					<button onClick={closeSuccessModal}>Close</button>
				  </div>
				</Modal>
				
				
	</div>
  );
}

export default VotingStage;